<template>
  <div id="addVehicleDialog">
    <el-dialog
      :title="isAdd ? '添加服务器' : '编辑服务器'"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="100px"
      >
        <el-form-item label="服务器名称">
          <el-input
            v-model="addForm.serverName"
            placeholder="请输入服务器名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务器IP" prop="serverIp">
          <el-input
            v-model="addForm.serverIp"
            placeholder="请输入服务器IP"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="serverUsername">
          <el-input
            v-model="addForm.serverUsername"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="serverPassword">
          <el-input
            v-model="addForm.serverPassword"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户端ID" prop="serverClientId">
          <el-input
            v-model="addForm.serverClientId"
            placeholder="请输入客户端ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户端密钥" prop="serverClientSecret">
          <el-input
            v-model="addForm.serverClientSecret"
            placeholder="请输入客户端密钥"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      isAdd: true,
      addForm: {
        serverName: "",
        serverIp: "",
        serverUsername: "",
        serverPassword: "",
        serverClientId: "",
        serverClientSecret: "",
      },
      addFormRules: {
        serverIp: [
          { required: true, message: "请输入服务器IP", trigger: "blur" },
        ],
        serverUsername: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        serverPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        serverClientId: [
          { required: true, message: "请输入客户端ID", trigger: "blur" },
        ],
        serverClientSecret: [
          { required: true, message: "请输入客户端密钥", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.isAdd = true;
      this.$set(this, "addForm", {
        serverName: "",
        serverIp: "",
        serverUsername: "",
        serverPassword: "",
        serverClientId: "",
        serverClientSecret: "",
      });
      this.$refs["addForm"].resetFields();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        let url = "/server/add";
        if (!this.isAdd) {
          url = "/server/edit ";
        }
        let res = await this.$http.post(url, {
          ...this.addForm,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("添加成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#addVehicleDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
